'use strict';

Gri.module({
  name: 'card-delta',
  ieVersion: null,
  $el: $('.card-delta'),
  container: '.card-delta',
  fn: function () {

    // region variables
    const $this = this.$el;
    let heights = [];
    // endregion

    // region run
    setTimeout(function () {
      $this.each(function () {
        heights.push($(this).outerHeight());
      });

      let maxHeight = Math.max.apply(undefined, heights);
      $this.outerHeight(maxHeight);
    }.bind(this), 1000);
    // endregion

    // region events

    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion

  }
});
